import {FetchWs} from "@/functions/FetchWs";
import {GraficosInterfaces} from "./GraficosInterfaces"
import moment from "moment";

export async function getDados(vue: any, dataInicio: string, dataFim: string) {
    const chamados = await getChamados(vue, dataInicio, dataFim);
    vue.chamadosAbertosFechados = processaChamadosAbertosFechados(chamados.data)
    vue.chamadosPrazo = processaChamadosPrazo(chamados.data)
    vue.chamadosPorCategoria = processaChamadosPorCategorias(chamados.data)
    vue.chamadosPorPrioridade = processaChamadosPorPrioridade(chamados.data)
    vue.chamadosPorCliente = processaChamadosPorCliente(chamados.data)
    vue.complexidadesPorProgramador = processaComplexidadesPorProgramador(chamados.data)
    vue.pontuacaoPorProgramador = processaPontuacaoPorProgramador(chamados.data)
    vue.chamadosPorProgramador = processaChamadosPorProgramador(chamados.data)
    vue.chamadosAtrasadosPorProgramador = processaChamadosAtrasadosPorProgramador(chamados.data)
    vue.retrabalhosPorProgramador = processaRetrabalhosPorProgramador(chamados.data)
    return chamados;
}

async function getChamados(vue: any, dataInicio: string, dataFim: string) {
    const query = `?encerramento_inicio=${dataInicio} 00:00:00&encerramento_fim=${dataFim} 00:00:00`;
    const response = await FetchWs('chamado/get-all' + query);
    return response;
}

function processaChamadosAbertosFechados(chamados: any) {
    const retorno: GraficosInterfaces = {
        labels: [],
        datasets: [
            {
                "label": "Abertos",
                "borderColor": "red",
                "data": []
            },
            {
                "label": "Fechados",
                "borderColor": "green",
                "data": []
            }
        ]
    }
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_data_criacao) {
            return;
        }
        const dtCriacao = moment(chamado.cha_data_criacao, "YYYY-MM-DD H:mm:ss").format('DD/MM')
        if (!retorno.labels.includes(dtCriacao)) {
            retorno.labels.push(dtCriacao)
        }
    })
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_data_encerramento) {
            return;
        }
        const dtEnc = moment(chamado.cha_data_encerramento, "YYYY-MM-DD H:mm:ss").format('DD/MM')
        if (!retorno.labels.includes(dtEnc)) {
            retorno.labels.push(dtEnc)
        }
    })
    retorno.labels.forEach((label: string, key: number) => {
        retorno.datasets[0].data[key] = 0;
        retorno.datasets[1].data[key] = 0;
    })
    chamados.forEach((chamado: any) => {
        const dtCriacao = moment(chamado.cha_data_criacao, "YYYY-MM-DD H:mm:ss").format('DD/MM')
        const dtEnc = moment(chamado.cha_data_encerramento, "YYYY-MM-DD H:mm:ss").format('DD/MM')
        if (retorno.labels.includes(dtCriacao)) {
            if (!dtCriacao) {
                return;
            }
            const keyCri: string = getKeyByValue(retorno.labels, dtCriacao)  || '';
            retorno.datasets[0].data[keyCri] += 1
        }
        if (retorno.labels.includes(dtEnc)) {
            if (!dtEnc) {
                return;
            }
            const keyEnc: string = getKeyByValue(retorno.labels, dtEnc)  || '';
            retorno.datasets[1].data[keyEnc] += 1
        }
    })
    return retorno
}

function processaChamadosPrazo(chamados: any) {
    const retorno: GraficosInterfaces = {
        labels: [],
        datasets: [
            {
                "label": "No Prazo",
                "borderColor": "green",
                "data": []
            },
            {
                "label": "Atrasados",
                "borderColor": "red",
                "data": []
            },
            {
                "label": "Retrabalhos",
                "borderColor": "orange",
                "data": []
            }
        ]
    }

    chamados.forEach((chamado: any) => {
        if (!chamado.cha_data_encerramento) {
            return;
        }
        const dtEnc = moment(chamado.cha_data_encerramento, "YYYY-MM-DD H:mm:ss").format('DD/MM')
        if (!retorno.labels.includes(dtEnc)) {
            retorno.labels.push(dtEnc)
        }
    })
    retorno.labels.forEach((label: string, key: number) => {
        retorno.datasets[0].data[key] = 0;
        retorno.datasets[1].data[key] = 0;
        retorno.datasets[2].data[key] = 0;
    })
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_data_criacao) {
            return;
        }
        if (!chamado.cha_data_encerramento) {
            return;
        }
        const dtPrazo = moment(chamado.prazo_dia + ' 23:59:00', "YYYY-MM-DD H:mm:ss")
        const dtEnc = moment(chamado.cha_data_encerramento, "YYYY-MM-DD H:mm:ss")
        if (retorno.labels.includes(dtEnc.format('DD/MM'))) {
            const keyEnc: string = getKeyByValue(retorno.labels, dtEnc.format('DD/MM'))  || '';
            if (dtPrazo.format('x') >= dtEnc.format('x')) {
                retorno.datasets[0].data[keyEnc] += 1
            } else {
                retorno.datasets[1].data[keyEnc] += 1
            }
            if (chamado.cha_retrabalho) {
                retorno.datasets[2].data[keyEnc] += 1
            }
        }
    })
    return retorno
}

function processaChamadosPorCategorias(chamados: any) {
    const retorno: GraficosInterfaces = {
        labels: [],
        datasets: [
            {
                "backgroundColor": [],
                "data": []
            }
        ]
    }
    chamados.forEach((chamado: any) => {
        if (!chamado.ccp_nome) {
            return;
        }
        if (!retorno.labels.includes(chamado.ccp_nome)) {
            retorno.labels.push(chamado.ccp_nome)
        }
    })
    retorno.labels.forEach((label, key) => {
        retorno.datasets[0].data[key] = 0;
        retorno.datasets[0].backgroundColor[key] = getRandHexColor();
    })
    chamados.forEach((chamado: any) => {
        if (!chamado.ccp_nome) {
            return;
        }
        if (retorno.labels.includes(chamado.ccp_nome)) {
            const key: string = getKeyByValue(retorno.labels, chamado.ccp_nome)  || '';
            retorno.datasets[0].data[key] += 1
        }
    })
    return retorno
}

function processaChamadosPorPrioridade(chamados: any) {
    const retorno: GraficosInterfaces = {
        labels: [],
        datasets: [
            {
                "backgroundColor": [],
                "data": []
            }
        ]
    }
    chamados.forEach((chamado: any) => {
        if (!chamado.ccp_prioridade) {
            return;
        }
        if (!retorno.labels.includes(chamado.ccp_prioridade)) {
            retorno.labels.push(chamado.ccp_prioridade)
        }
    })
    retorno.labels.forEach((label: string, key: number) => {
        retorno.datasets[0].data[key] = 0;
        retorno.datasets[0].backgroundColor[key] = getRandHexColor();
    })
    chamados.forEach((chamado: any) => {
        if (!chamado.ccp_prioridade) {
            return;
        }
        if (retorno.labels.includes(chamado.ccp_prioridade)) {
            const key: string = getKeyByValue(retorno.labels, chamado.ccp_prioridade)  || '';
            retorno.datasets[0].data[key] += 1
        }
    })
    return retorno
}

function processaChamadosPorCliente(chamados: any) {
    const retorno: GraficosInterfaces = {
        labels: [],
        datasets: [
            {
                "backgroundColor": [],
                "data": []
            }
        ]
    }
    chamados.forEach((chamado: any) => {
        if (!chamado.cli_nome_empresa) {
            return;
        }
        if (!retorno.labels.includes(chamado.cli_nome_empresa)) {
            retorno.labels.push(chamado.cli_nome_empresa)
        }
    })
    retorno.labels.forEach((label: string, key: number) => {
        retorno.datasets[0].data[key] = 0;
        retorno.datasets[0].backgroundColor[key] = getRandHexColor();
    })
    chamados.forEach((chamado: any) => {
        if (!chamado.cli_nome_empresa) {
            return;
        }
        if (retorno.labels.includes(chamado.cli_nome_empresa)) {
            const key: string = getKeyByValue(retorno.labels, chamado.cli_nome_empresa)  || '';
            retorno.datasets[0].data[key] += 1
        }
    })
    return retorno
}

function processaComplexidadesPorProgramador(chamados: any) {
    const retorno: GraficosInterfaces = {
        labels: [],
        datasets: [
            {
                "label": [],
                "data": [],
                "backgroundColor": [],
            }
        ]
    }
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_atendente) {
            return;
        }
        if (!retorno.labels.includes(chamado.ccp_nome)) {
            retorno.labels.push(chamado.ccp_nome)
        }
    })
    const chamadosPorProgramador: { [index: string]: any } = {}
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_atendente) {
            return;
        }
        if (!chamadosPorProgramador[chamado.cha_atendente]) {
            chamadosPorProgramador[chamado.cha_atendente] = []
        }
        chamadosPorProgramador[chamado.cha_atendente].push(chamado)
    })
    Object.entries(chamadosPorProgramador).forEach((programador: any, key: number) => {
        retorno.datasets[key] = {
            "label": programador[0],
            "data": [],
            "backgroundColor": getRandHexColor()
        };

        retorno.labels.forEach(() => retorno.datasets[key].data.push(0))
        programador[1].forEach((chamado: any) => {
            const keyValue: string = getKeyByValue(retorno.labels, chamado.ccp_nome)  || '';
            retorno.datasets[key].data[keyValue]++
        })
    })
    return retorno
}

function processaPontuacaoPorProgramador(chamados: any) {
    const retorno: GraficosInterfaces = {
        labels: [],
        datasets: [
            {
                "backgroundColor": [],
                "data": []
            }
        ]
    }
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_atendente) {
            return;
        }
        if (!retorno.labels.includes(chamado.cha_atendente)) {
            retorno.labels.push(chamado.cha_atendente)
        }
    })
    retorno.labels.forEach((label: string, key: number) => {
        retorno.datasets[0].data[key] = 0;
        retorno.datasets[0].backgroundColor[key] = getRandHexColor();
    })
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_atendente || !chamado.ccp_pontos) {
            return;
        }
        if (retorno.labels.includes(chamado.cha_atendente)) {
            const key: string = getKeyByValue(retorno.labels, chamado.cha_atendente)  || '';
            retorno.datasets[0].data[key] += chamado.pontos_totais
        }
    })
    return retorno
}

function processaChamadosPorProgramador(chamados: any) {
    const retorno: GraficosInterfaces = {
        labels: [],
        datasets: [
            {
                "backgroundColor": [],
                "data": []
            }
        ]
    }
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_atendente || !chamado.cha_data_encerramento) {
            return;
        }
        if (!retorno.labels.includes(chamado.cha_atendente)) {
            retorno.labels.push(chamado.cha_atendente)
        }
    })
    retorno.labels.forEach((label: string, key: number) => {
        retorno.datasets[0].data[key] = 0;
        retorno.datasets[0].backgroundColor[key] = getRandHexColor();
    })
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_atendente || !chamado.cha_data_encerramento) {
            return;
        }
        if (retorno.labels.includes(chamado.cha_atendente)) {
            const key: string = getKeyByValue(retorno.labels, chamado.cha_atendente)  || '';
            retorno.datasets[0].data[key] += 1
        }
    })
    return retorno
}

function processaChamadosAtrasadosPorProgramador(chamados: any) {
    const retorno: GraficosInterfaces = {
        labels: [],
        datasets: [
            {
                "backgroundColor": [],
                "data": []
            }
        ]
    }
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_atendente || !chamado.cha_data_encerramento) {
            return;
        }
        if (!retorno.labels.includes(chamado.cha_atendente)) {
            retorno.labels.push(chamado.cha_atendente)
        }
    })
    retorno.labels.forEach((label: string, key: number) => {
        retorno.datasets[0].data[key] = 0;
        retorno.datasets[0].backgroundColor[key] = getRandHexColor();
    })
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_atendente || !chamado.cha_data_encerramento) {
            return;
        }
        const dtPrazo = moment(chamado.prazo_dia + ' 23:59:00', "YYYY-MM-DD H:mm:ss")
        const dtEncerramento = moment(chamado.cha_data_encerramento, "YYYY-MM-DD H:mm:ss")
        if (retorno.labels.includes(chamado.cha_atendente)) {
            const key: string = getKeyByValue(retorno.labels, chamado.cha_atendente)  || '';
            if (dtEncerramento.format('x') > dtPrazo.format('x')) {
                retorno.datasets[0].data[key] += 1
            }
        }
    })
    return retorno
}

function processaRetrabalhosPorProgramador(chamados: any) {
    const retorno: GraficosInterfaces = {
        labels: [],
        datasets: [
            {
                "backgroundColor": [],
                "data": []
            }
        ]
    }
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_atendente || !chamado.cha_data_encerramento) {
            return;
        }
        if (!retorno.labels.includes(chamado.cha_atendente)) {
            retorno.labels.push(chamado.cha_atendente)
        }
    })
    retorno.labels.forEach((label, key) => {
        retorno.datasets[0].data[key] = 0;
        retorno.datasets[0].backgroundColor[key] = getRandHexColor();
    })
    chamados.forEach((chamado: any) => {
        if (!chamado.cha_atendente || !chamado.cha_data_encerramento) {
            return;
        }
        if (retorno.labels.includes(chamado.cha_atendente)) {
            const key: string = getKeyByValue(retorno.labels, chamado.cha_atendente)  || '';
            retorno.datasets[0].data[key] += chamado.cha_retrabalho
        }
    })
    return retorno
}

function getKeyByValue(object: any, value: any) {
    return Object.keys(object).find(key => object[key] === value);
}

function getRandHexColor() {
    return '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
}