<template>
  <line-chart :height="300" :chart-data="data" :options="options"></line-chart>
</template>

<script>
import {Chart, registerables} from 'chart.js';

Chart.register(...registerables);
import {LineChart} from 'vue-chart-3';

export default {
  name: "ChamadosPrazo",
  components: {
    LineChart
  },
  props: ['data'],
  setup() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Chamados',
          align: 'start',
          font: {
            size: 20,
            weight: 'bold',
            lineHeight: 1.2,
          },
        },
        subtitle: {
          display: true,
          text: 'No Prazo / Atrasados / Retrabalhos',
          align: 'start'
        }
      },
    };
    return {options};
  }
}
</script>

<style scoped>

</style>