<template>
  <DoughnutChart :height="600" :chart-data="data" :options="options"></DoughnutChart>
</template>

<script>
import {Chart, registerables} from 'chart.js';

Chart.register(...registerables);
import {DoughnutChart} from 'vue-chart-3';

export default {
  name: "ChamadosPorProgramador",
  components: {
    DoughnutChart
  },
  props: ['data'],
  setup() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Chamados',
          font: {
            size: 16,
            weight: 'bold',
            lineHeight: 1.2,
          },
        },
        subtitle: {
          display: true,
          text: 'Por Programador',
        }
      },
    };
    return {options};
  }
}
</script>

<style>

</style>