<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md12 lg12 xl12">
      <va-card-content>
        <div class="row justify-center align-center va-spacing-x-1">
          <div class="flex">
            <va-date-input :label="`Data Início:`" v-model="dataInicio"/>
          </div>
          <div class="flex">
            <va-date-input :label="`Data Fim:`" :reset-on-close="false" v-model="dataFim"/>
          </div>
          <div class="flex">
            <va-button @click="refresh" type="submit" variant="primary">Filtrar</va-button>
          </div>
        </div>

        <va-divider />

        <div class="row justify-center align-start">
          <div class="flex card-grafico xl6 lg6 md6 sm12 xs12">
            <ChamadosAbertosFechados :data="chamadosAbertosFechados"></ChamadosAbertosFechados>
          </div>
          <div class="flex card-grafico xl6 lg6 md6 sm12 xs12">
            <ChamadosPrazo :data="chamadosPrazo"></ChamadosPrazo>
          </div>
        </div>

        <div class="row justify-center align-start">
          <div class="flex card-grafico xl10 lg10 md10 sm12 xs12">
            <ChamadosPorCategoria :data="chamadosPorCategoria"></ChamadosPorCategoria>
          </div>
          <div class="flex card-grafico xl2 lg2 md2 sm12 xs12">
            <ChamadosPorPrioridade :data="chamadosPorPrioridade"></ChamadosPorPrioridade>
          </div>
        </div>

        <div class="row justify-center align-start">
          <div class="flex card-grafico xl12 lg12 md12 sm12 xs12">
            <ChamadosPorCliente :data="chamadosPorCliente"></ChamadosPorCliente>
          </div>
          <div class="flex card-grafico xl12 lg12 md12 sm12 xs12">
            <ComplexidadesPorProgramador :data="complexidadesPorProgramador"></ComplexidadesPorProgramador>
          </div>
        </div>

        <div class="row justify-center align-start">
          <div class="flex card-grafico xl3 lg3 md6 sm6 xs12">
            <PontuacaoPorProgramador :data="pontuacaoPorProgramador"></PontuacaoPorProgramador>
          </div>
          <div class="flex card-grafico xl3 lg3 md6 sm6 xs12">
            <ChamadosPorProgramador :data="chamadosPorProgramador"></ChamadosPorProgramador>
          </div>
          <div class="flex card-grafico xl3 lg3 md6 sm6 xs12">
            <ChamadosAtrasadosPorProgramador :data="chamadosAtrasadosPorProgramador"></ChamadosAtrasadosPorProgramador>
          </div>
          <div class="flex card-grafico xl3 lg3 md6 sm6 xs12">
            <RetrabalhosPorProgramador :data="retrabalhosPorProgramador"></RetrabalhosPorProgramador>
          </div>
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import ChamadosAbertosFechados from "./graficos-componentes/ChamadosAbertosFechados";
import ChamadosPrazo from "./graficos-componentes/ChamadosPrazo";
import ChamadosPorCategoria from "./graficos-componentes/ChamadosPorCategoria";
import ChamadosPorPrioridade from "./graficos-componentes/ChamadosPorPrioridade";
import ChamadosPorCliente from "./graficos-componentes/ChamadosPorCliente";
import ComplexidadesPorProgramador from "./graficos-componentes/ComplexidadePorProgramador";
import PontuacaoPorProgramador from "./graficos-componentes/PontuacaoPorProgramador";
import ChamadosPorProgramador from "./graficos-componentes/ChamadosPorProgramador";
import ChamadosAtrasadosPorProgramador from "./graficos-componentes/ChamadosAtrasadosPorProgramador";
import RetrabalhosPorProgramador from "./graficos-componentes/RetrabalhosPorProgramador";
import {getDados} from "./GraficosFunctions";

export default {
  components: {
    ChamadosAbertosFechados,
    ChamadosPrazo,
    ChamadosPorCategoria,
    ChamadosPorPrioridade,
    ChamadosPorCliente,
    ComplexidadesPorProgramador,
    PontuacaoPorProgramador,
    ChamadosPorProgramador,
    ChamadosAtrasadosPorProgramador,
    RetrabalhosPorProgramador
  },
  data() {
    return {
      dados: false,
      dataInicio: '',
      dataFim: '',
      errors: [],
      chamadosAbertosFechados: [],
      chamadosPrazo: [],
      chamadosPorCategoria: [],
      chamadosPorPrioridade: [],
      chamadosPorCliente: [],
      complexidadesPorProgramador: [],
      pontuacaoPorProgramador: [],
      chamadosPorProgramador: [],
      chamadosAtrasadosPorProgramador: [],
      retrabalhosPorProgramador: []
    }
  },
  async mounted() {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    this.dataInicio = firstDay
    this.dataFim = lastDay
    await this.refresh()
  },
  methods: {
    padStr(i) {
      return (i < 10) ? "0" + i : "" + i;
    },
    async refresh() {
      this.errors = false;
      await getDados(this, this.padStr(this.dataInicio.getFullYear()) + '-' + this.padStr(this.dataInicio.getMonth() + 1) + '-' + this.padStr(this.dataInicio.getDate()), this.padStr(this.dataFim.getFullYear()) + '-' + this.padStr(this.dataFim.getMonth() + 1) + '-' + this.padStr(this.dataFim.getDate()));
    },
  }
}

</script>

<style scoped>
.card-grafico > div {
  border: 1px solid #dfdfdf;
  padding: 1rem;
  margin: 2px;
  border-radius: 15px;
}

</style>