<template>
  <BarChart :height="400" :chart-data="data" :options="options"></BarChart>
</template>

<script>
import {Chart, registerables} from 'chart.js';

Chart.register(...registerables);
import {BarChart} from 'vue-chart-3';

export default {
  name: "ChamadosPorProgramador",
  components: {
    BarChart
  },
  props: ['data'],
  setup() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Categorias',
          align: 'start',
          font: {
            size: 20,
            weight: 'bold',
            lineHeight: 1.2,
          },
        },
        subtitle: {
          display: true,
          text: 'Por Programador',
          align: 'start',
          padding: {
            bottom: 20
          }
        }
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      }
    };
    return {options};
  }
}
</script>

<style>

</style>